export default function signup () {
  return {
    customer_first_name: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => element.value.length >= 2,
        message: 'Должно быть больше 2 символов'
      }
    },
    customer_last_name: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => element.value.length >= 2,
        message: 'Должно быть больше 2 символов'
      }
    },
    customer_phone: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => /^[+]?[0-9]{1,5}[\-\s]?[\(]?[0-9]{1,5}[\)]?[\-\s.\/0-9]{4,15}$/i.test(element.value),
        message: 'Неверный формат'
      }
    },
    customer_email: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => /^[A-ZА-Я0-9._%+\-]+@[A-ZА-Я0-9.\-]+\.[A-ZА-Я]{2,4}$/i.test(element.value),
        message: 'Неверный формат'
      }
    },
    customer_password: {
      isPresent: {
        check: element => element.value.length > 0,
        message: 'Обязательно для заполнения'
      },
      isValid: {
        check: element => element.value.length >= 4,
        message: 'Должно быть больше 4 символов'
      }
    },
    customer_rules_consent: {
      isPresent: {
        check: element => element.checked,
        message: 'Необходимо ознакомиться и согласиться'
      }
    }
  }
}
