import debounce from 'lodash-es/debounce'

import * as validateRules from './rules'

export default () => {
  const forms = document.querySelectorAll('.needs-validation')
  const validations = sessionStorage.validations ? JSON.parse(sessionStorage.validations) : { errors: {}, warnings: {}}
  let typingTimer //timer identifier

  Array.prototype.slice.call(forms).forEach(form => {
    form.addEventListener('submit', event => {
      if (!form.checkValidity()) {
        form.reportValidity()
        event.preventDefault()
        event.stopPropagation()
      }

      form.classList.add('was-validated')
    }, false)

    for (const el of [...form.elements]) {
      if (el.value?.length > 0 || (el.type === 'checkbox' && el.checked))
        el.classList.remove('pristine')

      el.addEventListener('keyup', _e => {
        clearTimeout(typingTimer)
        typingTimer = setTimeout(() => makeDirty(el), 2500)
      }, false)
      el.addEventListener('keydown', _e => clearTimeout(typingTimer), false)
      el.addEventListener('change', _e =>  makeDirty(el, true), false)
    }
    form.addEventListener('keyup', debounce(event => validateCheck(event, form, false), false), 500)
    form.addEventListener('change', event => validateCheck(event, form, true), false)

    setTimeout(() => {
      if (form.checkValidity()) {
        const event = new Event('init')
        Object.defineProperty(event, 'target', { writable: false, value: form })
        validateCheck(event, form, false)
      }
    }, 100)
  })

  const validateCheck = (event, form, each = false) => {
    const errorsBlock = form.getElementsByClassName('form_errors')[0]
    errorsBlock.innerHTML = ''
    const validateType = form.dataset.forModal
    const validators = validateRules[validateType]()
    const elements = each ? [...form.elements] : [event.target]

    elements.forEach(el => {
      if ((validations.errors[validateType] && validations.errors[validateType][el.id]) ||
        !el.classList.contains('pristine')) {
        checkAppValidity(validateType, el, validators)
      }
    })
    const submits = form.querySelectorAll('button[type="submit"]')
    const addButtons = form.querySelectorAll('a[role="button"]')

    const pristineFields = [...form.getElementsByClassName('pristine')].map(el => {
      if (el.required) return el.id
    }).filter(el => el)

    const inters = intersections(Object.keys(validators), [...new Set(pristineFields)])
    const invalid = (validations.errors[validateType] && Object.keys(validations.errors[validateType]).length !== 0) ||
      inters.length > 0

    submits.forEach(submit => {
      submit.disabled = invalid
    })
    if (invalid) {
      addButtons.forEach(add => add.classList.add('disabled'))
    } else {
      addButtons.forEach(add => add.classList.remove('disabled'))
      delete validations.errors[validateType]
    }
  }

  const checkAppValidity = (validateType, element, validators) => {
    const newValidations = validations

    if (!Object.keys(validators).includes(element.id)) return newValidations
    if (element.value.length === 0) delete newValidations.errors[validateType][element.id]

    newValidations.errors[validateType] = validations.errors[validateType] || {}
    newValidations.warnings[validateType] = validations.warnings[validateType] || {}

    for (const key of Object.keys(validators[element.id])) {
      const checker = validators[element.id][key]
      const aria = element.getAttribute('aria-describedby')
      const errorField = document.getElementById(aria) || document.createElement('div')
      errorField.id = aria

      if (checker.check(element)) {
        if (element.value.length === 0) return setNeutral(validateType, element, newValidations)
        setValid(validateType, errorField, element, newValidations)
      } else { setInvalid(validateType, errorField, element, checker.message, newValidations) }
    }

    const newValidationByType = { ...validations[validateType], ...newValidations[validateType] }
    sessionStorage.validations = JSON.stringify({ ...validations, ...newValidationByType })
    return newValidations
  }

  const makeDirty = (el, change = false) => {
    if (!change && el.value.length < 3) return

    el.classList.remove('pristine')
    clearTimeout(typingTimer)
  }

  const setInvalid = (type, errorField, element, message, newValidations) => {
    element.classList.add('is-invalid')
    element.classList.remove('is-valid')
    newValidations.errors[type][element.id] = message
    errorField.innerHTML = message
    errorField.classList.remove('hidden')
    errorField.classList.add('invalid-feedback')
    errorField.classList.remove('valid-feedback')
    errorField.classList.remove('fade_out')
    element.parentElement.appendChild(errorField)
    errorField.classList.add('fade_in')
    element.setCustomValidity(message)
  }

  const setValid = (type, errorField, element, newValidations) => {
    element.classList.add('is-valid')
    element.classList.remove('is-invalid')
    delete newValidations.errors[type][element.id]
    errorField.classList.remove('fade_in')
    errorField.classList.add('fade_out')
    errorField.classList.add('valid-feedback')
    errorField.classList.remove('invalid-feedback')
    errorField.classList.add('hidden')
    element.setCustomValidity('')
  }

  const setNeutral = (type, element, newValidations) => {
    element.classList.remove('is-valid')
    element.classList.remove('is-invalid')
    delete newValidations.errors[type][element.id]
  }

  const intersections = (array1, array2) => {
    return array1.filter(value => array2.indexOf(value) !== -1)
  }
}
